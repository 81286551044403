<script setup lang="ts">
import src756 from '~/images/captainjet/monaco/hero/756w.webp'
import src1512 from '~/images/captainjet/monaco/hero/1512w.webp'
import src3024 from '~/images/captainjet/monaco/hero/3024w.webp'

const props = defineProps<{
	sizes: string
}>()
</script>

<template>
	<img
		:srcSet="`
			${src756} 756w,
			${src1512} 1512w,
			${src3024} 3024w,
		`"
		:sizes="props.sizes"
		alt="Private jet flying in front of cloudy mountain peaks."
	/>
</template>
