<template>
	<div>
		<!--Instagram-->
		<CompanyLinksSocialButton href="https://www.instagram.com/captainjetofficial">
			<svg viewBox="0 0 17 17" height="17">
				<path fill="currentColor" fill-rule="evenodd" d="M8 .9756c-2.1723 0-2.4454.0095-3.2997.0476-.8512.0381-1.4323.1747-1.9405.3716-.5272.2033-.9718.4796-1.4164.9242-.4446.4446-.7178.8924-.9242 1.4164C.2223 4.2436.0857 4.8247.0476 5.679.0096 6.5302 0 6.8033 0 8.9756s.0095 2.4454.0476 3.2997c.0381.8511.1747 1.4323.3716 1.9436.2033.5272.4796.9719.9242 1.4165.4446.4446.8924.7177 1.4164.9241.5082.197 1.0894.3335 1.9437.3716.8543.0381 1.1242.0477 3.2997.0477 2.1754 0 2.4454-.0096 3.2997-.0477.8511-.0381 1.4323-.1746 1.9436-.3716.5272-.2032.9719-.4795 1.4165-.9241.4446-.4446.7177-.8924.9241-1.4165.197-.5081.3335-1.0893.3716-1.9436.0381-.8543.0477-1.1242.0477-3.2997s-.0096-2.4454-.0477-3.2997c-.0381-.8512-.1746-1.4324-.3716-1.9437-.2032-.5272-.4795-.9718-.9241-1.4164-.4446-.4446-.8924-.7177-1.4165-.9242-.5081-.1969-1.0893-.3334-1.9436-.3716C10.4454.9851 10.1723.9756 8 .9756Zm0 3.8936c-2.2676 0-4.1096 1.8388-4.1096 4.1096 0 2.2707 1.8389 4.1095 4.1096 4.1095 2.2707 0 4.1096-1.8388 4.1096-4.1095 0-2.2708-1.8389-4.1096-4.1096-4.1096Zm0 6.7741c-1.4736 0-2.6677-1.1941-2.6677-2.6677S6.5264 6.3079 8 6.3079s2.6677 1.194 2.6677 2.6677c0 1.4736-1.1941 2.6677-2.6677 2.6677Zm5.2306-6.9393a.9591.9591 0 1 1-1.9182 0 .9591.9591 0 1 1 1.9182 0Z" clip-rule="evenodd" />
			</svg>
		</CompanyLinksSocialButton>

		<!--Twitter-->
		<CompanyLinksSocialButton href="https://twitter.com/CaptainJetHQ">
			<svg viewBox="0 0 16 14" height="14">
				<path fill="currentColor" d="M14.04 3.76v.42c.01 4.27-3.13 9.2-8.86 9.2-1.7 0-3.35-.51-4.78-1.46a6.12 6.12 0 0 0 4.61-1.34A3.14 3.14 0 0 1 2.1 8.33c.47.1.95.08 1.4-.06A3.2 3.2 0 0 1 1 5.11v-.04a3 3 0 0 0 1.42.4 3.31 3.31 0 0 1-.96-4.3 8.77 8.77 0 0 0 6.42 3.37c-.25-1.12.1-2.3.9-3.09a3.05 3.05 0 0 1 4.42.14c.7-.14 1.37-.4 1.98-.78a3.24 3.24 0 0 1-1.37 1.78 6.24 6.24 0 0 0 1.79-.5 6.51 6.51 0 0 1-1.56 1.67Z" />
			</svg>
		</CompanyLinksSocialButton>

		<!--Facebook-->
		<CompanyLinksSocialButton href="https://www.facebook.com/CaptainJetOfficial">
			<svg viewBox="0 0 8 16" height="16">
				<path fill="currentColor" d="m7.48 8.86.41-2.73h-2.6V4.36c0-.75.37-1.48 1.53-1.48H8V.56S6.93.38 5.9.38c-2.13 0-3.53 1.3-3.53 3.67v2.08H0v2.73h2.37v6.6a9.32 9.32 0 0 0 2.93 0v-6.6h2.18Z" />
			</svg>
		</CompanyLinksSocialButton>

		<!--LinkedIn-->
		<CompanyLinksSocialButton href="https://www.linkedin.com/company/captainjet">
			<svg viewBox="0 0 16 17" height="17">
				<path fill="currentColor" d="M14.82.98H1.18A1.18 1.18 0 0 0 0 2.16v13.63a1.18 1.18 0 0 0 1.18 1.19h13.64A1.18 1.18 0 0 0 16 15.79V2.16A1.18 1.18 0 0 0 14.82.98ZM4.77 14.6h-2.4V6.96h2.4v7.65Zm-1.2-8.7a1.38 1.38 0 1 1 1.28-.85 1.36 1.36 0 0 1-1.29.85Zm10.07 8.7h-2.4v-4.17c0-1.23-.53-1.61-1.2-1.61-.72 0-1.42.53-1.42 1.64v4.14h-2.4V6.97h2.3v1.06h.04a2.62 2.62 0 0 1 2.28-1.27c1.35 0 2.8.8 2.8 3.13v4.72Z" />
			</svg>
		</CompanyLinksSocialButton>
	</div>
</template>
