<script setup lang="ts">
import { useProperty } from '@/composables/useProperty'
import { route } from '@/composables/route'

const iosDownloadLink = useProperty('security.features.ios_download_link')
const androidDownloadLink = useProperty('security.features.android_download_link')
const phoneNumber = useProperty('security.features.phone_number')
const phoneNumberClean = computed(() => phoneNumber.value?.replaceAll(' ', ''))
const phoneNumberWhatsApp = computed(() => phoneNumberClean.value?.replaceAll('+', ''))

const charterEmails = useProperty('security.features.charter_emails')
const charterEmail = computed(() => charterEmails.value[0])
const charterEmailSubject = 'CaptainJet x Monaco'
</script>

<template>
	<nav>
		<CompanyLinksButton is="InertiaLink" :href="route('captainjet.web.index')" secondary>
			Website
		</CompanyLinksButton>
		<CompanyLinksButton is="a" v-if="iosDownloadLink" :href="iosDownloadLink" target="_blank" secondary>
			iOS App
		</CompanyLinksButton>
		<CompanyLinksButton is="a" v-if="androidDownloadLink" :href="androidDownloadLink" target="_blank" secondary>
			Android App
		</CompanyLinksButton>
		<CompanyLinksButton is="a" :href="`https://wa.me/${phoneNumberWhatsApp}`" target="_blank">
			WhatsApp
		</CompanyLinksButton>
		<CompanyLinksButton is="a" :href="`tel:${phoneNumberClean}`">
			Call us
		</CompanyLinksButton>
		<CompanyLinksButton is="a" :href="`mailto:${charterEmail}?subject=${encodeURIComponent(charterEmailSubject)}`" target="_blank">
			Email us
		</CompanyLinksButton>
	</nav>
</template>
