<script setup lang="ts">
import { route } from '@/composables/route'
import { parse } from '~/ts/utils/datetime'
import BookingData = Domain.BookingCaptainJet.Data.BookingData

const $props = withDefaults(defineProps<{
	highlighted: boolean
	booking: BookingData
}>(), {
	highlighted: false,
})

// Initially, show the first and last trips.
const trips = ref($props.booking.legs!.map((trip, index) => ({ ...trip, visible: index === 0 || index === $props.booking.legs!.length - 1 })))
const hiddenTrips = computed(() => trips.value.filter(({ visible }) => !visible))
const offers = computed(() => $props.booking.quotations?.filter(({ status }) => status === 'quoted')?.length ?? 0)

// On mobile, show the first and last dates.
const mobileDate = computed(() => {
	const first = parse($props.booking.legs!.at(0)!.departs_at_local_time).format('D MMM. YY')
	if ($props.booking.legs!.length === 1) {
		return first
	} else {
		const last = parse($props.booking.legs!.at(-1)!.departs_at_local_time).format('D MMM. YY')
		return `${first} > ${last}`
	}
})

function displayMore() {
	trips.value.forEach((trip) => {
		if (hiddenTrips.value.some(({ id }) => id === trip.id)) {
			trip.visible = true
		}
	})
}
</script>

<template>
	<InertiaLink :href="route('captainjet.web.booking.quotation.show', { booking })">
		<div
			:id="`booking-${booking.id}`"
			class="relative flex flex-col rounded-xl bg-white transition lg:flex-row"
			:class="{ highlight: highlighted }"
		>
			<div class="flex items-center justify-between rounded-t-xl bg-turquoise-100 py-1 pl-4 pr-2 text-turquoise-600 lg:size-0 lg:p-0">
				<span class="text-sm font-medium leading-6 tracking-tighter lg:hidden" v-text="mobileDate" />
				<BookingRequestsBadge
					:status="booking.status"
					:offers="offers"
					class="flex lg:absolute lg:right-4 lg:top-4"
				/>
			</div>

			<!-- one row -->
			<div class="flex-1">
				<div
					v-for="(trip, index) in trips"
					:key="index"
				>
					<div :class="[{ 'hidden' : !trip.visible }, { ' flex-1 lg:flex' : trip.visible }]">
						<!-- desktop only -->
						<div
							:class="['hidden w-[120px] px-10 pb-3 font-medium tracking-tighter text-blue-400 lg:flex lg:flex-col lg:items-center lg:justify-center']"
						>
							<p class="text-2xl font-bold" v-text="parse(trip.departs_at_local_time).format('D')" />
							<p class="text-xs" v-text="parse(trip.departs_at_local_time).format('MMM. YY')" />
						</div>

						<!-- airports -->
						<div class="relative flex flex-1 px-4 py-3 lg:border-l lg:border-dashed lg:border-turquoise-100 lg:pl-8">
							<div v-if="index === 0" class="absolute -left-2 top-0 hidden h-2 w-4 rounded-b-full bg-turquoise-50 lg:block" />
							<div v-if="index === trips.length - 1" class="absolute -left-2 bottom-0 hidden h-2 w-4 rounded-t-full bg-turquoise-50 lg:block" />

							<div class="flex flex-col items-center justify-between pb-6 pt-1">
								<div class="size-3.5 rounded-full border-[3px] border-warning-500 bg-white" />
								<div class="flex-1 border-l-2 border-dotted border-turquoise-300" />
								<div class="size-3.5 rounded-full border-[3px] border-warning-500 bg-white" />
							</div>

							<div class="ml-4 flex flex-col space-y-3 tracking-tighter text-blue-500 lg:ml-5">
								<div>
									<p class="font-medium leading-5" v-text="trip.departure_airport?.name" />
									<p v-if="trip.departure_airport?.served_city" class="text-15 text-blue-300" v-text="trip.departure_airport?.served_city" />
								</div>
								<div>
									<p class="font-medium leading-5" v-text="trip.arrival_airport?.name" />
									<p v-if="trip.arrival_airport?.served_city" class="text-15 text-blue-300" v-text="trip.arrival_airport?.served_city" />
								</div>
							</div>
						</div>
					</div>

					<div
						v-if="trips.length > 2 && index === trips.length - 2 && hiddenTrips.length > 0"
						class="relative flex justify-center border-l border-dashed border-turquoise-100 lg:ml-[120px] lg:mr-12"
					>
						<BaseButton
							type="button"
							variant="secondary"
							size="xs"
							class="relative z-10 px-4 text-xs"
							@click.prevent="displayMore"
						>
							Load {{ hiddenTrips.length }} more
						</BaseButton>
						<div class="absolute inset-0 flex items-center">
							<div class="h-px w-full bg-turquoise-100 lg:ml-10" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</InertiaLink>
</template>

<style scoped lang="postcss">
.highlight {
  animation: highlight-animation ease-in-out 1.5s 2;
}

@keyframes highlight-animation {
  0%,
  50%,
  100% {
    @apply bg-white;
  }
  25%,
  75% {
		@apply bg-orange-50;
		@apply ring ring-orange-100;
  }
}
</style>
