<script setup lang="ts">
const props = defineProps<{
	href: string
}>()
</script>

<template>
	<a
		class="size-10 rounded-full border-2 border-transparent bg-blue-500 text-white transition-colors duration-75 ease-out hover:bg-blue-400 focus:border-blue-600 disabled:bg-blue-100"
		:href="props.href"
		target="_blank"
	>
		<div class="flex h-full items-center justify-center">
			<slot />
		</div>
	</a>
</template>
