<script setup lang="ts">
import { Combobox, ComboboxInput, ComboboxOption, ComboboxOptions } from '@headlessui/vue'
import { watchDebounced } from '@vueuse/core'
import { getAirportNearPlace, getAirportByIcao, fetchLocations, loadMoreAirportsNearPlace } from '~/ts/airports'
import Flag from '@/components/Icon/Flag.vue'

const container = ref<HTMLDivElement>()
const input = ref<typeof ComboboxInput>()
const query = ref('')
const selectedAirport = ref<Resource.Airport>()
const airports = ref<Resource.Airport[]>([])
const places = ref<Resource.Place[]>([])
const airportsByPlace = ref<Resource.Airport[]>([])
const canLoadMore = ref(false)
const loadingMore = ref(false)

const props = defineProps<{
	id: string
	modelValue?: Resource.Airport
	optionsClass?: string
	inputClass?: string
}>()

const emit = defineEmits(['update:modelValue'])

watch(() => props.modelValue, async(airport) => {
	if (!airport || !airport.icao_code) {
		selectedAirport.value = undefined
		return
	}

	selectedAirport.value = await getAirportByIcao(airport.icao_code)
}, { immediate: true })

watch(selectedAirport, (airport) => {
	if (airport) {
		input.value!.$el.value = ''
	}

	emit('update:modelValue', toRaw(airport))
})

watchDebounced(query, async(query) => {
	try {
		const response = await fetchLocations(query)
		airportsByPlace.value = await getAirportNearPlace(response.places?.[0]?.id)
		airports.value = response.airports.filter(({ icao_code }: Resource.Airport) => !airportsByPlace.value.some((airport) => airport.icao_code === icao_code))
		places.value = response.places
		canLoadMore.value = airportsByPlace.value.length > 0
	} catch {}
}, { debounce: 100 })

const placeholder = computed(() => {
	if (selectedAirport.value) {
		return
	}

	return props.id.includes('departure')
		? 'Where are you flying from?'
		: 'Where would you like to go?'
})

async function loadMore() {
	loadingMore.value = true
	const result = await loadMoreAirportsNearPlace(places.value.at(0)?.id)
	loadingMore.value = false
	airportsByPlace.value.push(...result)
	canLoadMore.value = result.length > 0

	if (canLoadMore.value) {
		setTimeout(() => {
			container.value?.scrollTo({
				top: container.value.scrollHeight,
				behavior: 'smooth',
			})
		}, 25)
	}
}
</script>

<template>
	<Combobox v-model="selectedAirport" as="div" class="relative">
		<ComboboxInput
			:id="id"
			ref="input"
			:class="inputClass"
			class="w-full font-medium text-blue-500 placeholder-shown:italic placeholder-shown:placeholder:text-turquoise-400"
			autocomplete="off"
			:placeholder="placeholder"
			@change="query = $event.target.value"
		/>
		<div v-if="selectedAirport" class="absolute inset-0 flex items-center justify-between pl-0.5">
			<Flag
				v-if="selectedAirport.country"
				:code="selectedAirport.country?.iso_code.toLowerCase()"
				:name="selectedAirport.country?.name"
				class="mr-2 w-4"
			/>

			<div class="mr-4 flex-1 truncate text-sm text-blue-300">
				<span class="font-medium text-blue-500">{{ selectedAirport.name }}</span>, {{ selectedAirport.served_city }}
			</div>
		</div>

		<ComboboxOptions
			:class="optionsClass"
			class="absolute inset-x-0 z-20 -mx-5 overflow-hidden rounded-lg shadow focus:outline-none lg:mt-2"
		>
			<div
				ref="container"
				:class="{ 'divide-y divide-turquoise-100' : airports.length && places.length }"
				class="max-h-96 overflow-y-auto rounded-lg bg-white text-sm lg:max-h-[28rem] lg:w-full"
			>
				<!-- Airports by place -->
				<div v-if="places.length" class="sticky top-0 z-10 border-b border-turquoise-100 bg-white">
					<div class="flex cursor-default items-center bg-blue-300 px-6 py-1.5 font-medium uppercase tracking-widest text-white">
						Places
					</div>
					<div class="px-5 py-2 text-blue-500">
						<span class="font-medium">{{ places.at(0)?.main_text }}</span>
						<span v-if="places.at(0)?.secondary_text">, {{ places.at(0)?.secondary_text }}</span>
					</div>
				</div>
				<div v-auto-animate="{ duration: 250 }">
					<ComboboxOption
						v-for="airport in airportsByPlace"
						:key="airport.icao_code"
						v-slot="{ active }"
						:value="airport"
						as="template"
					>
						<li class="flex cursor-pointer px-5 py-4" :class="{ 'bg-turquoise-50' : active }">
							<div class="pt-1">
								<IconFlightUp class="size-4 text-blue-400" />
							</div>
							<div class="ml-4">
								<h4 class="font-medium text-blue-500">
									{{ airport.name }}
								</h4>
								<div v-if="airport.country" class="mt-1 flex text-sm text-blue-400">
									<Flag
										:code="airport.country.iso_code.toLowerCase()"
										:name="airport.country.name"
										class="mr-2 w-4"
									/>
									<span>{{ airport.served_city }}</span>
									<template v-if="airport.distance">
										<span class="mx-1.5">&mdash;</span>
										<span
											:title="`${airport.distance} meters`"
											v-text="Intl.NumberFormat(undefined, { unit: 'meter', maximumSignificantDigits: 2 }).format(airport.distance / 1000) + ' km'"
										/>
									</template>
								</div>
							</div>
						</li>
					</ComboboxOption>
				</div>
				<li v-if="canLoadMore" class="py-2">
					<button
						:disabled="loadingMore"
						class="flex w-full cursor-pointer items-center px-5 py-1 font-medium transition"
						:class="{
							'text-blue-300 hover:text-blue-500': !loadingMore,
							'text-blue-100': loadingMore,
						}"
						@click.prevent="loadMore"
					>
						<template v-if="!loadingMore">
							Load more airports...
						</template>
						<template v-else>
							Loading... <IconLoading class="ml-2 size-4" />
						</template>
					</button>
				</li>

				<!-- Airports -->
				<div
					v-if="airports.length"
					class="flex cursor-default items-center bg-blue-300 px-6 py-1.5 font-medium uppercase tracking-widest text-white"
				>
					Airports
				</div>
				<div v-auto-animate="{ duration: 250 }">
					<ComboboxOption
						v-for="airport in airports"
						:key="airport.icao_code"
						v-slot="{ active }"
						:value="airport"
						as="template"
					>
						<li class="flex cursor-pointer px-5 py-4" :class="{ 'bg-turquoise-50' : active }">
							<div class="pt-1">
								<IconFlightUp class="size-4 text-blue-400" />
							</div>
							<div class="ml-4">
								<h4 class="font-medium text-blue-500">
									{{ airport.name }}
								</h4>
								<div v-if="airport.country" class="mt-1 flex">
									<Flag
										:code="airport.country?.iso_code.toLowerCase()"
										:name="airport.country.name"
										class="mr-2 w-4"
									/>
									<span class="text-blue-400">{{ airport.served_city }}</span>
								</div>
							</div>
						</li>
					</ComboboxOption>
				</div>

				<!-- Empty state -->
				<div v-if="!airports.length && !places.length" class="flex items-center space-x-5 p-5 text-sm text-blue-500">
					<IllustrationGlobe class="h-10 shrink-0" />
					<p>Sorry, there is no place nor airport corresponding to your search.</p>
				</div>
			</div>
		</ComboboxOptions>
	</Combobox>
</template>
