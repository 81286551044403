<script setup lang="ts">
import { parsePhoneNumberFromString } from 'libphonenumber-js'

const $props = defineProps<{
	address?: string
}>()

const addressWithoutPhone = computed(() => {
	if (!$props.address) {
		return
	}

	return $props.address
		.split('\n')
		.filter((part) => !part.startsWith('+')).join('\n')
})

const googleMapsUrl = computed(() => {
	if (!addressWithoutPhone.value) {
		return
	}

	return `https://www.google.com/maps/search/?api=1&query=${addressWithoutPhone.value.replaceAll('\n', '+')}`
})

const phoneNumber = computed(() => {
	if (!$props.address) {
		return
	}

	return $props.address
		.split('\n')
		.find((part) => part.startsWith('+'))
})

const phoneNumberUrl = computed(() => {
	if (!phoneNumber.value) {
		return
	}

	return `tel:${phoneNumber.value}`
})

const formattedPhoneNumber = computed(() => {
	if (!phoneNumber.value) {
		return
	}

	return parsePhoneNumberFromString(phoneNumber.value)?.formatInternational() ?? phoneNumber.value
})
</script>

<template>
	<p>
		<component
			:is="googleMapsUrl ? 'a' : 'p'"
			:href="googleMapsUrl"
			class="block whitespace-pre-wrap"
			v-text="addressWithoutPhone ?? 'None'"
		/>
		<a
			v-if="phoneNumber"
			:href="phoneNumberUrl"
			v-text="formattedPhoneNumber"
		/>
	</p>
</template>
