<script setup lang="ts">
const props = withDefaults(defineProps<{
	is?: string
	secondary?: boolean
}>(), {
	is: 'button',
})
</script>

<template>
	<component
		:is="props.is"
		:class="[
			'h-11 overflow-hidden whitespace-nowrap rounded-full border font-inter font-semibold transition-colors duration-75 ease-out focus:border-2 focus:border-turquoise-600',
			props.secondary ? 'border-blue-100 bg-white text-turquoise-500 hover:bg-turquoise-100 focus:bg-turquoise-100 disabled:bg-[#F6F7F9] disabled:text-turquoise-300'
			: 'border-transparent bg-turquoise-500 text-white hover:bg-turquoise-400 disabled:bg-turquoise-300',
		]"
	>
		<div class="flex h-full items-center justify-center">
			<slot />
		</div>
	</component>
</template>
