import dayjs from 'dayjs'

interface ParseDateTimeOptions {
	dateFormat?: string
	timeFormat?: string
	dateTimeFormat?: string
	utc?: boolean
}

export function parse(date?: string | number | dayjs.Dayjs | Date | null | undefined) {
	return dayjs(date)
}

export function getReturnDate(previous?: string | Date) {
	if (previous) {
		return dayjs(previous).add(3, 'day')
	}

	return dayjs().add(1, 'day').set('minute', 0)
}

export function parseDateTime(raw: string | null | undefined, options?: ParseDateTimeOptions) {
	if (!raw) {
		return {
			date: '-',
			time: '',
		}
	}

	const datetime = raw.slice(0, -6)
	const parsed = options?.utc
		? dayjs.utc(datetime)
		: dayjs(datetime)

	return {
		date: parsed.format(options?.dateFormat ?? 'ddd DD MMM. YY'),
		time: parsed.format(options?.timeFormat ?? 'HH:mm A Z'),
		datetime: parsed.format(options?.timeFormat ?? 'ddd DD MMM. YY [at] HH:mm A [LT]'),
	}
}

export function parseFlightTime(flightTime: number | null | undefined, format?: string) {
	return dayjs.duration(flightTime || 0, 'minutes').format(format ?? 'HH[h]mm')
}
