<script setup lang="ts">
import { route } from '@/composables/route'

useHead({
	title: 'CaptainJet',
})

const tagline = 'When experience meets innovation'
</script>


			<script lang="ts">
			import layout from '@/layouts/marketing.vue'
			export default { layout }
			</script>
			<template>
		
	<!--Mobile-->
	<div class="flex flex-1 flex-col overflow-y-auto overflow-x-hidden md:hidden">
		<div class="relative shrink-0 overflow-hidden">
			<CompanyLinksHero
				class="absolute bottom-0 left-0 w-full object-cover object-bottom"
				style="height: 155%;"
				sizes="100vw"
			/>
			<div
				class="relative flex flex-col items-center gap-y-6 p-6 pt-11 text-white"
				style="background: linear-gradient(180deg, #23466B 0%, rgba(35, 70, 107, 0.4) 100%);"
			>
				<InertiaLink
					class="flex flex-col items-center gap-y-6"
					:href="route('captainjet.web.index')"
				>
					<CompanyLinksJetIcon />
					<CompanyLinksLogo class="max-w-full" />
				</InertiaLink>
				<span class="text-center font-nova text-xl font-bold">{{ tagline }}</span>
			</div>
		</div>
		<div class="flex flex-1 flex-col justify-between bg-white p-8 pb-6">
			<div class="flex flex-col gap-y-10">
				<CompanyLinksButtons class="flex flex-col gap-y-7" />
				<CompanyLinksSocialButtons class="flex items-center gap-x-4.5 self-center" />
			</div>
			<CompanyLinksQualities class="mt-10 max-w-full self-center text-blue-200" />
		</div>
	</div>

	<!--Desktop-->
	<div class="relative hidden flex-1 flex-col items-center overflow-y-auto p-12 pt-0 md:flex">
		<CompanyLinksHero class="absolute left-0 top-0 size-full object-cover" sizes="100vw" />
		<div class="flex flex-1 flex-col justify-center pb-12 pt-8">
			<transition
				appear
				mode="out-in"
				enter-active-class="duration-500 transition-all"
				enter-from-class="opacity-0 -translate-x-2"
				enter-to-class="opacity-100 translate-x-0"
				leave-active-class="duration-500 transition-all"
				leave-from-class="opacity-100 translate-x-0"
				leave-to-class="opacity-0 -translate-x-2"
			>
				<div class="relative flex w-[634px] flex-col items-center justify-center gap-y-8 rounded-[32px] bg-white/70 p-8 pt-14 text-blue-500 backdrop-blur-2xl">
					<InertiaLink
						class="flex flex-col items-center gap-y-6"
						:href="route('captainjet.web.index')"
					>
						<CompanyLinksJetIcon />
						<CompanyLinksLogo />
					</InertiaLink>
					<span class="text-center font-nova text-xl font-bold text-blue-400">{{ tagline }}</span>
					<CompanyLinksButtons class="flex w-96 flex-col gap-y-6" />
					<CompanyLinksSocialButtons class="flex items-center gap-x-4.5" />
				</div>
			</transition>
		</div>
		<CompanyLinksQualities class="relative shrink-0 text-white" />
	</div>
</template>
